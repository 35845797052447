<template>
	<Document
		:fileOpFunc="fileOpFunc"
		:defaultDoc="defaultDoc"
		:previewUrl="previewUrl"
		:downloadUrl="downloadUrl"
		:multiple="true"
	></Document>
</template>

<script>
import api from "@/api/document/docfile"
import Document from "@/components/BusinessComponents/Document/Index.vue"

const {
	apiGetPage,
	deleteFile,
	addFile,
	uploadFile,
	apiGetPermission,
	apiSetPermission,
	sortFile
} = api
export default {
	name: "proDocument",
	data() {
		return {
			fileOpFunc: {
				apiGetPage,
				deleteFile,
				addFile,
				uploadFile,
				apiGetPermission,
				apiSetPermission,
				sortFile
			},
			previewUrl: `${this.$baseUrl}/document/docfile`,
			downloadUrl: `${this.$baseUrl}/document/docfile`,
			defaultDoc: {
				id: "1",
				name: "资料中心"
			}
		}
	},
	components: {
		Document
	}
}
</script>

<style scoped></style>
